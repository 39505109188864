import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Root from '../pages/unauthenticated/root';
import Test from '../pages/unauthenticated/test';
import Login from '../pages/unauthenticated/login';
import ViessmannLogin from '../pages/unauthenticated/viessmann-login';
import ForgotPassword from '../pages/unauthenticated/forgot-password';
import ResetPassword from '../pages/unauthenticated/reset-password';
import MyAccount from '../pages/authenticated/my-account';
import MyCompany from '../pages/authenticated/my-company';
import Dashboard from '../pages/authenticated/dashboard';
import Register from '../pages/unauthenticated/register';
import { AuthenticatedLayout, ProtectedPage, Permissions, Platforms } from "carrier-fe";
import SystemSelection from '../pages/unauthenticated/register/system-selection';
import Overview from '../pages/unauthenticated/register/overview';
import UserDetails from '../pages/unauthenticated/register/user-details';
import SystemSpecific from '../pages/unauthenticated/register/system-specific';
import CompanyDetails from '../pages/unauthenticated/register/company-details';
import Completion from '../pages/unauthenticated/register/completion';
import RegisterId from '../pages/unauthenticated/register/id';
import RegisterResetPassword from '../pages/unauthenticated/register-password-reset';

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        {/* TODO - REMOVE TEST PAGE */}
        <Route
          index
          path="/test"
          element={<Test />}
        />

        <Route
          index
          path="/"
          element={<Root />}
        />

        {/* TODO - ACCESS THIS PAGE AND HAVE AND VALID TOKEN -> REDIRECT TO DASHBOARD */}
        <Route
          index
          path="/login"
          element={<Login />}
        />
        <Route
          index
          path="/viessmann/login"
          element={<ViessmannLogin />}
        />

        {/* TODO - CLEAR ANY TOKENS STORED */}
        <Route
          index
          path="/forgot-password"
          element={<ForgotPassword />}
        />
        <Route
          index
          path="/reset-password"
          element={<ResetPassword />}
        />
        <Route
          index
          path="/onboarding/:userId"
          element={<RegisterResetPassword />}
        />

        <Route
          path="/register"
          element={<Register />}
        >
          <Route
            path="/register/:registrationId"
            element={<RegisterId />}
          />
          <Route
            path="/register/:registrationId/overview"
            element={<Overview />}
          />
          <Route
            path="/register/:registrationId/system-selection"
            element={<SystemSelection />}
          />
          <Route
            path="/register/:registrationId/system-specific"
            element={<SystemSpecific />}
          />
          <Route
            path="/register/:registrationId/user-details"
            element={<UserDetails />}
          />
          <Route
            path="/register/:registrationId/company-details"
            element={<CompanyDetails />}
          />
          <Route
            path="/register/:registrationId/complete"
            element={<Completion />}
          />
        </Route>

        {/*
				 * TODO - FOLLOWING ROUTES NEED TO BE AUTH PROTECTED
				 *      - IS A TOKEN SET IN THE COOKIE?
				 *      - IS THAT TOKEN VALID (CHECK VIA THE SERVER?
				 *      - IF NOT -> LOGOUT AND REDIRECT TO LOGIN
				 */}
        <Route
          path="/"
          element={<AuthenticatedLayout platform={Platforms.DASHBOARD} />}
        >
          <Route
            index
            path="/dashboard"
            element={
              <ProtectedPage
                requiredPermissions={Permissions.DASHBOARD_PLATFORM_VIEW}
              >
                <Dashboard />
              </ProtectedPage>
            }
          />
          <Route
            index
            path="/my-account"
            element={
              <ProtectedPage
                requiredPermissions={Permissions.MY_COMPANY}
              >
                <MyAccount />
              </ProtectedPage>
            }
          />
          <Route
            index
            path="/my-company"
            element={
              <ProtectedPage
                requiredPermissions={Permissions.MY_COMPANY}
              >
                <MyCompany />
              </ProtectedPage>
            }
          />
        </Route>

        {/* TODO - MAKE A PRETTY PAGE IN THE COMPONENT LIBRARY */}
        <Route
          path="*"
          element={<p>404</p>}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
