import axios from "axios";
import {
  API_ENDPOINTS,
  Button,
  AuthPaneContainer,
  getLocalUrl,
  customToast,
  getThemeFromUrl,
  LargeThemeLogo,
  route,
  StringHelpers,
  Title,
  useDisabledContext,
  useLangContext,
  Variants,
  ButtonTypes,
  getThemeAuthCover
} from "carrier-fe";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

function Overview() {
  const { registration, crud } = useLangContext();
  const { registrationId } = useParams();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { disabled } = useDisabledContext();

  const navigate = useNavigate();

  const lang = registration?.page?.step_one[getThemeFromUrl()];

  useEffect(() => {
    if (!isSubmitting) return;
    nextStep();
  }, [isSubmitting])

  const nextStep = () => {
    axios.patch(
      route(API_ENDPOINTS.REGISTRATION.STEP_ONE, { registrationId: registrationId || '' })
    )
      .then(() => navigate(
        `/register/${registrationId}/system-selection`
      ))
      .catch(err => customToast({
        variant: Variants.Danger,
        title:
          err?.response?.data?.message || 'Something went wrong...',
      }))
      .finally(() => setIsSubmitting(false))
  };

  function wrapEmailWithMailto(text: string): (string | JSX.Element)[] {
    const emailPattern = /([\w.-]+@[\w.-]+\.\w+)/g;
  
    return text.split(emailPattern).map((part, index) =>
        emailPattern.test(part) ? (
            <a key={index} href={`mailto:${part}`}>{part}</a>
        ) : (
            part
        )
    );
  }

  return (
    <AuthPaneContainer
      fillerImage={getLocalUrl() + getThemeAuthCover()}
      variant="FORM"
      style={{ "--auth-content-max-width": "90%" } as React.CSSProperties} 
    >
      <LargeThemeLogo theme={getThemeFromUrl()} style={{ width: 128 }} />
      <Title
        title={StringHelpers.title(lang?.title || 'Register')}
        className="mb-3 text-center w-100" />

      <p>
        {lang?.description}
      </p>

      <ul className="row row-cols-1 row-cols-sm-2 g-4 list-unstyled">
        {lang?.systems?.map((system: { label: string, tooltip: string[] }) => (
          <li key={system.label} className="col p-3 border rounded shadow-sm">
            <strong>{system.label}</strong>
            <ul className="mt-2">
              {system.tooltip.map((sentence, index) => (
                <li key={index} className="text-muted">
                  {sentence}
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>

      {lang?.update_notice && 
        <p>{wrapEmailWithMailto(lang?.update_notice)}</p>
      }  
    
      <div className="d-flex w-100 justify-content-between">
      <Button
        label={lang?.links?.login || 'Back to login'}
        variant={Variants.Dark}
        type={ButtonTypes.Outline}
        href="/login"
        disabled={disabled}
      />
        <Button
          label={isSubmitting
            ? crud?.buttons.next.submitting || 'Saving...'
            : crud?.buttons.next.default || 'Next'}
          onClick={() => setIsSubmitting(true)}
          disabled={isSubmitting || disabled}
          variant={Variants.Info}
        />
      </div>
    </AuthPaneContainer>
  );
}

export default Overview;

