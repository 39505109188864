import axios from 'axios';
import {
	API_ENDPOINTS,
	AppContainer,
	AuthPaneContainer,
	Button,
	customToast,
	FormInput,
	getLocalUrl,
	getThemeAuthCover,
	getThemeFromUrl,
	LangContext,
	LargeThemeLogo,
	route,
	Spinner, StringHelpers,
	Title,
	Variants,
} from 'carrier-fe';
import { useContext, useEffect, useState } from 'react';
import {Link } from 'react-router-dom';

function ForgotPassword() {
	const { getLangPage } = useContext(LangContext);

	const [data, setData] = useState({
		email: '',
	});
	const [errors, setErrors] = useState<{
		email: string[];
	} | null>(null);

	const [submitting, setSubmitting] = useState<boolean>(false);

	const [lang, setLang] = useState<any>(null);

	useEffect(() => {
		if (lang === null) {
			load();
		}
	}, []);

	useEffect(() => {
		if (submitting) {
			setErrors(null);
			submit();
		}
	}, [submitting]);

	const load = async () => {
		getLangPage('dashboard/forgot_password')
			.then((res: any) => {
				if (typeof res === "undefined" || res === null) {
					setLang([]);
				} else {
					setLang(res);

					document.title = res?.page.title ?? 'Forgot password';
				}
			})
			.catch((err: any) => console.error(err));
	};

	const submit = () => {
		axios
			.post(route(API_ENDPOINTS.AUTH.FORGOT_PASSWORD), data)
			.then((res) => {
				customToast({
					title: res.data.message,
					variant: Variants.Success,
				});
			})
			.catch((error: any) => {
				if (!!error && !!error.response && !!error.response.data) {
					if (!!error.response.data.message) {

						customToast({
							title: error.response.data.message,
							variant: Variants.Danger,
						});
					}

					if (!!error.response.data.data && !!error.response.data.data.errors) {
						setErrors(error.response.data.data.errors);
					}
				} else {
					console.log(error);
					customToast({
						title: 'An error occurred. Please refresh the page to try again.',
						variant: Variants.Danger,
					});
				}
			})
			.finally(() => setSubmitting(false));
	};

	const handleFieldUpdate = (field: string, value: any) => {
		let cloned: any = structuredClone(data);

		cloned[field] = value;

		setData(cloned);
	};

	const renderButtonTitle = () => {
		if (submitting) {
			return lang?.page.submit_button.submitting || 'Sending...';
		}

		return lang?.page.submit_button.default || 'Send reset email';
	};

	// TODO - Create a branded loading spinner
	if (lang === null) {
		return (
			<div className="d-flex align-items-center vh-100 justify-content-center">
				<Spinner />
			</div>
		);
	}

	return (
		<AppContainer>
			<AuthPaneContainer fillerImage={getLocalUrl() + getThemeAuthCover()}>
				<LargeThemeLogo theme={getThemeFromUrl()} style={{ width: 128 }} />
				<Title
					title={StringHelpers.title(lang?.page.title || 'Forgot password')}
					className="mb-3 text-center w-100"
				/>
				<FormInput
					type="email"
					label={StringHelpers.title(lang?.fields.email || 'Email')}
					name="email"
					value={data.email}
					onChange={(value) => handleFieldUpdate('email', value)}
					errorMessages={!!errors && !!errors.email ? errors.email : null}
					disabled={submitting}
				/>
				<div
					className="d-flex justify-content-end align-items-center mb-5"
					style={{ marginTop: '-1rem' }}
				>
					<Link
						className="text-decoration-none"
						to="/login"
					>
						{lang?.page.links.login || 'Back to login'}
					</Link>
				</div>
				<Button
					label={renderButtonTitle()}
					onClick={() => setSubmitting(true)}
					variant={Variants.Info}
					className="w-100 justify-content-center mb-3"
					disabled={submitting}
				/>
				<div className="d-flex flex-column justify-content-center align-items-center">
					<Link
						className="text-decoration-none"
						to="/register"
					>
						{lang?.page.links.register || 'No account? Register here.'}
					</Link>
				</div>
			</AuthPaneContainer>
		</AppContainer>
	);
}

export default ForgotPassword;
