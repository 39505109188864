import axios from "axios";
import {
    API_ENDPOINTS,
    AuthPaneContainer,
    Button,
    ButtonTypes,
    customToast, 
    getLocalUrl, 
    getThemeFromUrl, 
    route, 
    StringHelpers, 
    Title, 
    useDisabledContext, 
    useForm, 
    useLangContext, 
    Variants,
    LargeThemeLogo,
    useNavigationGuard,
    GasSafeLookup,
    getThemeAuthCover
} from "carrier-fe";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

type SystemSelectionType = {
    company_gas_boiler_systems_enabled: string;
    company_heat_pump_systems_enabled: string;
    company_solar_systems_enabled: string;
    company_other_systems_enabled: string;
};

type SystemSpecificType = {
    company_gas_safe_number: string;
    company_gas_safe_confirmed: string;
    company_mcs_number: string;
    company_mcs_number_confirmation: string;
};

const defaultData: SystemSpecificType = {
    company_gas_safe_number: '',
    company_gas_safe_confirmed: '0',
    company_mcs_number: '',
    company_mcs_number_confirmation: '0'
}

function SystemSpecific() {
    const { registration, crud, fields } = useLangContext();
    const { registrationId } = useParams();
    const { disabled } = useDisabledContext();
    const navigate = useNavigate();

    useNavigationGuard({ message: 'Are you sure' });

    const lang = registration?.page?.step_three[getThemeFromUrl()] || null;

    const [systemSelection, setSystemSelection] = useState<SystemSelectionType>({
        company_gas_boiler_systems_enabled: '0',
        company_heat_pump_systems_enabled: '0',
        company_solar_systems_enabled: '0',
        company_other_systems_enabled: '0'
    });

    const [gasSafe, setGasSafe] = useState<boolean>(false);

    const patch = async (data: SystemSpecificType) => {
        data.company_mcs_number_confirmation = data.company_mcs_number_confirmation === 'yes' ? '1' : '0';

        const res = await axios.patch(
            route(API_ENDPOINTS.REGISTRATION.STEP_THREE, { registrationId: registrationId || '' }),
            data
        );

        return res;
    };

    const { Submit, setValues, store, isSuccess, Input,errors } = useForm(patch, defaultData);
    
    useEffect(() => {
        axios.get(
            route(
                API_ENDPOINTS.REGISTRATION.STEP_TWO,
                { registrationId: registrationId || '' }
            )
        ).then((res) => setSystemSelection(res.data.data))
        .catch(err => customToast({
            variant: Variants.Danger,
            title: err?.response?.data?.message || 'Something went wrong...'
        }))
    }, []);

    useEffect(() => {
        axios.get(
            route(
                API_ENDPOINTS.REGISTRATION.STEP_THREE,
                { registrationId: registrationId || '' }
            )
        ).then((res) => setValues(res.data.data))
        .catch(err => customToast({
            variant: Variants.Danger,
            title: err?.response?.data?.message || 'Something went wrong...'
        }))
    }, []);

    useEffect(() => {
        if (!isSuccess) return;

        navigate(
            `/register/${registrationId}/user-details`
        );
    }, [isSuccess]);

    return (
        <AuthPaneContainer fillerImage={getLocalUrl() + getThemeAuthCover()} variant="FORM" style={{ "--auth-content-max-width": "90%" } as React.CSSProperties}>
            <LargeThemeLogo theme={getThemeFromUrl()} style={{ width: 128 }} />
            <Title
                title={StringHelpers.title(lang?.title || "System Specific")}
            />
            <div style={{ marginBottom: "3rem" }}></div>
            {systemSelection.company_gas_boiler_systems_enabled === '1' && (
                <div>
                    <p>
                        {StringHelpers.sentence(lang?.gas_safe_instruction || 'Please enter your company Gas Safe Registration Number')}
                    </p>
                    <GasSafeLookup
                     type="company"
                     displayCard
                     fetchOnLoad
                     gasSafeNumber={store.company_gas_safe_number}
                     gasSafeConfirmed={store.company_gas_safe_confirmed === '1'}
                     onGasSafeNumberChange={(value) => setValues({ company_gas_safe_number: value })}
                     onGasSafeConfirmedChange={(value) => setValues({ company_gas_safe_confirmed: value ? '1' : '0' })}
                     labels={{
                        gasSafeNumber:fields?.gas_safe_number,
                        gasSafeConfirmed:fields?.gas_safe_confirmation_generic,
                     }}
                     errors={{
                        gasSafeNumber: errors?.company_gas_safe_number ,
                        gasSafeConfirmed: errors?.company_gas_safe_confirmed 
                     }}
                     disabled={disabled}
                    />
                </div>
            )}
            <div style={{ marginBottom: "3rem" }}></div>
            {(systemSelection.company_solar_systems_enabled === '1' 
            || systemSelection.company_heat_pump_systems_enabled === '1') && (
                <div>
                    <p>
                        {StringHelpers.sentence(lang?.mcs_instruction || "Please enter your MCS number")}
                    </p>
                    {Input({
                        name: "company_mcs_number",
                        label: StringHelpers.sentence(fields?.company_mcs_number || 'MCS Number')
                    })}
                    {Input({
                        type: "select",
                        name: "company_mcs_number_confirmation",
                        label: StringHelpers.sentence(fields?.company_mcs_number_confirmation || 'MCS confirmation'),
                        options: [
                            {
                                label: "Please select...",
                                value: "",
                            },
                            {
                                label: "Yes",
                                value: "yes"
                            },
                            {
                                label: "No",
                                value: "no"
                            }
                        ]
                    })}
                </div>
            )}
            <div className="d-flex w-100 justify-content-between mt-4">
                <Button
                    label={lang?.links?.login || 'Back to login'}
                    variant={Variants.Dark}
                    type={ButtonTypes.Outline}
                    href="/login"
                    disabled={disabled}
                />
                <div className="d-flex gap-2">
                    <Button
                    label={crud?.buttons.previous.default || 'Previous'}
                    variant={Variants.Dark}
                    type={ButtonTypes.Text}
                    onClick={() => navigate(`/register/${registrationId}/system-selection`)}
                    disabled={disabled}
                    />
                    <Submit
                    defaultLabel={crud?.buttons.next.default || "Next"}
                    loadingLabel={crud?.buttons.next.submitting || "Saving..."}
                    disabled={disabled}
                    variant={Variants.Info}
                    />
                </div>
            </div>
        </AuthPaneContainer>
    );
}

export default SystemSpecific;
