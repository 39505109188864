import axios from 'axios';
import {
	API_ENDPOINTS,
	AppContainer,
	AuthPaneContainer,
	Button,
	customToast,
	FormInput,
	getLocalUrl,
	getThemeFromUrl,
	LargeThemeLogo,
	route,
	Spinner,
	Title,
	Variants,
	PasswordFieldAndRules,
	StringHelpers, setCookie, COOKIE_TYPES, useLangContext,
	getThemeAuthCover
} from 'carrier-fe';
import {useEffect, useState } from 'react';
import {useNavigate, useParams,  } from 'react-router-dom';

function RegisterResetPassword() {
  const { userId } = useParams();
	const navigate 			= useNavigate();

  const {crud, fields, registration} = useLangContext()
	const [submitting, setSubmitting]	= useState(false);
	const [data, setData] 				= useState({
		email: '',
		password: 				'',
		password_confirmation:	'',
	});

	const [errors, setErrors] = useState<{
		password: 				string[];
		password_confirmation:	string[];
	} | null>(null);

  useEffect(() => {
	  document.title = registration?.title || 'Onboarding';
  }, [registration])

	useEffect(() => {
		if (submitting) {
			setErrors(null);
			submit();
		}
	}, [submitting]);

  useEffect(() => {
    if (!userId) return;
    fetchUser();
  }, [userId])

  const fetchUser = () => {
    axios.get(route(API_ENDPOINTS.ONBOARDING, {userId: userId || ''}))
      .then(res => setData((e) => {
          return {
            ...e,
            email: res.data.data.email
          }
      }))
      .catch(error => {
				if (!!error && !!error.response && !!error.response.data) {
					if (!!error.response.data.message) {

						customToast({
							title: error.response.data.message,
							variant: Variants.Danger,
						});
					}

					if (!!error.response.data.data && !!error.response.data.data.errors) {
						setErrors(error.response.data.data.errors);
					}
				} else {
					customToast({
						title: 'An error occurred. Please refresh the page to try again.',
						variant: Variants.Danger,
					});
				}
      })
  }

	const submit = () => {
		axios
			.patch(route(API_ENDPOINTS.ONBOARDING, {userId: userId || ''}), data)
			.then((res) => {
				customToast({
					title: res.data.message,
					variant: Variants.Success,
				});

				login();
			})
			.catch((error: any) => {
				if (!!error && !!error.response && !!error.response.data) {
					if (!!error.response.data.message) {

						customToast({
							title: error.response.data.message,
							variant: Variants.Danger,
						});
					}

					if (!!error.response.data.data && !!error.response.data.data.errors) {
						setErrors(error.response.data.data.errors);
					}
				} else {
					console.log(error);
					customToast({
						title: 'An error occurred. Please refresh the page to try again.',
						variant: Variants.Danger,
					});
				}

				setSubmitting(false)
			});
	};

	const login = () => {
		axios
			.post<Response>(route(API_ENDPOINTS.AUTH.LOGIN), {
				username: data.email,
				password: data.password,
			})
			.then((res: any) => {
				const token = res.data.data.token.token;

				if (token) {
					setCookie(COOKIE_TYPES.TOKEN, token);
				}

				customToast({
					title: res.data.message,
					variant: Variants.Success,
				});

				navigate('/dashboard');
			})
			.catch((error: any) => {
				if (!!error && !!error.response && !!error.response.data) {
					if (!!error.response.data.message) {

						customToast({
							title: error.response.data.message,
							variant: Variants.Danger,
						});
					}

					navigate('/login');
				} else {
					console.log(error);
					customToast({
						title: 'An error occurred. Please refresh the page to try again.',
						variant: Variants.Danger,
					});

					navigate('/login');
				}
			})
			.finally(() => setSubmitting(false));
	}

	const handleFieldUpdate = (field: string, value: any) => {
		let cloned: any = structuredClone(data);

		cloned[field] = value;

		setData(cloned);
	};

	const renderButtonTitle = () => {
		if (submitting) {
			return crud?.buttons.login.submitting || 'Logging in...';
		}

		return crud?.buttons.login.default || 'Reset';
	};

	if (registration === null) {
		return (
			<div className="d-flex align-items-center vh-100 justify-content-center">
				<Spinner />
			</div>
		);
	}

	return (
		<AppContainer>
			<AuthPaneContainer fillerImage={getLocalUrl() + getThemeAuthCover()}>
				<LargeThemeLogo theme={getThemeFromUrl()} style={{ width: 128 }} />
				<Title
					title={StringHelpers.title(registration.title || 'Reset password')}
					className="mb-3 text-center w-100"
				/>
				<FormInput
					type="email"
					label={StringHelpers.title(fields.email || 'Email')}
					name="email"
					value={data.email}
					disabled={true}
					onChange={() => {}}
				/>
				<PasswordFieldAndRules
					password={{
						value:          data.password ?? '',
						label:          StringHelpers.title(fields.password || 'Password'),
						name:           'password',
						onChange:       (value: string) => handleFieldUpdate('password', value),
						errorMessages:  !!errors && !!errors.password ? errors.password : null,
					}}
					password_confirmation={{
						value:          data.password_confirmation ?? '',
						label:          StringHelpers.title(fields.password_confirmation || 'Confirm password'),
						name:           'password_confirmation',
						onChange:       (value: string) => handleFieldUpdate('password_confirmation', value),
						errorMessages:  !!errors && !!errors.password_confirmation ? errors.password_confirmation : null,
					}}
					lang={{
            declaration: fields.password.declaration || 'For a strong password please satisfy the below password requirements.',
            rules: {
              min_char: fields.password.min_char || 'Minimum 8 characters',
              lower: fields.password.lower || 'Contains lowercase characters',
              upper: fields.password.upper || 'Contains uppercase characters',
              numeric: fields.password.numeric || 'Contains numeric characters',
              symbols: fields.password.symbols || 'Contains symbols characters',
              confirmed: fields.password.confirmed || 'Passwords must match',
            }
					}}
				/>
				<Button
					label={renderButtonTitle()}
					onClick={() => setSubmitting(true)}
					className="w-100 justify-content-center mb-3"
					variant={Variants.Info}
					disabled={submitting}
				/>
			</AuthPaneContainer>
		</AppContainer>
	);
}

export default RegisterResetPassword;
