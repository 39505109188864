import {
  AuthPaneContainer,
  Button,
  getLocalUrl,
  Title,
  LargeThemeLogo,
  getThemeFromUrl,
  Variants,
  useLangContext,
  StringHelpers,
  getThemeAuthCover
} from "carrier-fe";
import SuccessIcon from "./icons";


function Completion() {
  const { registration } = useLangContext();

  const lang = registration?.page?.complete[getThemeFromUrl()] || null;

  return <AuthPaneContainer fillerImage={getLocalUrl() + getThemeAuthCover()} style={{ "--auth-content-max-width": "90%" } as React.CSSProperties}>
    <LargeThemeLogo theme={getThemeFromUrl()} style={{ width: 128 }} />
    <Title 
      title={StringHelpers.title(lang?.title || "Registration complete")}
    />
    <p>{lang?.article}</p>
    <div className="d-flex justify-content-center">
      <SuccessIcon />
    </div>
    <div className="d-flex justify-content-end">
      <Button
      label={lang?.links.login || "Login"}
      href="/login"
      variant={Variants.Info}
      />
    </div>
  </AuthPaneContainer>
}

export default Completion;
