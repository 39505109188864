import {
  useForm,
  route,
  API_ENDPOINTS,
  customToast,
  Variants,
  PostcodeLookup,
  AuthPaneContainer,
  getLocalUrl,
  useLangContext,
  getThemeFromUrl,
  Title,
  StringHelpers,
  useDisabledContext,
  Button,
  ButtonTypes,
  LargeThemeLogo,
  useNavigationGuard,
  getThemeAuthCover
} from "carrier-fe";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect } from "react"

const defaultData = {
  company_name: '',
  company_address_id: '',
  company_address_line_1: '',
  company_address_line_2: '',
  company_address_line_3: '',
  company_town_city: '',
  company_state_county: '',
  company_postcode_zipcode: '',
  company_local_authority_code: '',
  company_sub_country_name: '',
  company_country_code_iso_3: '',
  company_fgas_refcom_standard_number: '',
  company_refcom_elite_number: '',
}

type response = { data: typeof defaultData };

function CompanyDetails() {

  const { registrationId } = useParams();
  const { registration, crud, fields } = useLangContext();
  const { disabled } = useDisabledContext();
  const navigate = useNavigate();

  useNavigationGuard({ message: 'Are you sure' });

  const lang = registration?.page?.step_five[getThemeFromUrl()] || null;

  const isToshiba = getThemeFromUrl() === 'toshiba';

  const patch = async (data: typeof defaultData) => {
    const res = await axios.patch(route(API_ENDPOINTS.REGISTRATION.STEP_FIVE, { registrationId: registrationId || '' }), data)
    return res;
  }

  const { Submit, Input, setValues, errors, store, isSuccess } = useForm(patch, defaultData);

  useEffect(() => {
    axios.get<response>(route(API_ENDPOINTS.REGISTRATION.STEP_FIVE, { registrationId: registrationId || '' }))
      .then((res) => setValues(res.data.data))
      .catch(err => customToast({
        variant: Variants.Danger,
        title:
          err?.response?.data?.message || 'Something went wrong...',
      }))
  }, []);

  useEffect(() => {
    if (!isSuccess) return;

    navigate(
      `/register/${registrationId}/complete`
    );
  }, [isSuccess])

  return <AuthPaneContainer 
    fillerImage={getLocalUrl() + getThemeAuthCover()} 
    style={{ "--auth-content-max-width": "90%" } as React.CSSProperties}
    variant="FORM" 
 >
    <LargeThemeLogo theme={getThemeFromUrl()} style={{ width: 128 }} />
    <Title
      title={StringHelpers.title(lang?.title || "Company Details")}
    />
    {Input({
      name: 'company_name',
      label: StringHelpers.sentence(fields.company_name || 'Company name')
    })}
    {isToshiba && Input({
      name: 'company_fgas_refcom_standard_number',
      label: StringHelpers.sentence(fields.fgas_refcom_standard_number || 'FGAS / REFCOM standard number')
    })}
    {isToshiba && Input({
      name: 'company_refcom_elite_number',
      label: StringHelpers.sentence(fields.refcom_elite_number || 'REFCOM elite number')
    })}
    <PostcodeLookup
      data={store}
      setData={setValues}
      errors={errors}
      prefix="company_"
    />
    <div className="d-flex w-100 justify-content-between mt-4">
      <Button
        label={lang?.links?.login || 'Back to login'}
        variant={Variants.Dark}
        type={ButtonTypes.Outline}
        href="/login"
        disabled={disabled}
      />
      <div className="d-flex gap-2">
        <Button
          label={crud?.buttons.previous.default || 'Previous'}
          variant={Variants.Dark}
          type={ButtonTypes.Text}
          onClick={() => navigate(`/register/${registrationId}/user-details`)}
          disabled={disabled}
        />
        <Submit
          defaultLabel={crud?.buttons.next.default || "Next"}
          loadingLabel={crud?.buttons.next.submitting || "Saving..."}
          disabled={disabled}
          variant={Variants.Info}
        />
      </div>
    </div>
  </AuthPaneContainer>
}

export default CompanyDetails;
