import {
	PageLayout,
	FormInput,
	Button,
	API_ENDPOINTS,
	route,
	PostcodeLookup,
	DataTable,
	useDisabledContext,
	LangContext,
	StringHelpers,
	checkTheme,
	PasswordFieldAndRules,
	PhoneNumberField,
	DateFormats,
	SubTitle,
	Card,
	CardBody,
	Themes,
	useAuthorisationContext,
	Roles,
	customToast,
	BottomAnchoredControls,
	Variants,
	GasSafeLookup,
	WidthConstrainedContainer,
	useLangContext,
	Spinner,
} from 'carrier-fe';
import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { format, parse } from 'date-fns';

function MyAccount() {
	const { disabled, setDisabled } = useDisabledContext();
	const { hasRoleAccess } = useAuthorisationContext();
	const {fields,crud} = useLangContext()

	const { getLangPage } = useContext(LangContext);
	const [lang, setLang] = useState<any>(null);
	const [loading, setLoading] = useState<boolean>(false);

	const [data, setData] = useState<any>();
	const [submitting, setSubmitting] = useState<boolean>(false);
	const [errors, setErrors] = useState<any>(null);


	useEffect(() => {
		if (lang === null) {
			load();
		}

		getAccountData();
	}, []);

	useEffect(() => {
		if (submitting) {
			setDisabled(true);
			setErrors(null);
			updateAccountData();
		}
	}, [submitting]);

	const load = async () => {
		getLangPage('dashboard/my_account')
			.then((res: any) => {
				if (typeof res === 'undefined' || res === null) {
					setLang([]);
				} else {
					setLang(res);

					document.title = StringHelpers.title(
						res?.page.title || 'My Account'
					);
				}
			})
			.catch((err: any) => console.error(err));
	};

	const getAccountData = () => {
		setLoading(true);
		axios
			.get(route(API_ENDPOINTS.GENERIC.MY_ACCOUNT.UPDATE))
			.then((res) => setData(res.data.data))
			.catch((error) => {
				if (!!error && !!error.response && !!error.response.data) {
					if (!!error.response.data.message) {
						customToast({
							title: error.response.data.message,
							variant: Variants.Danger,
						});
					}
				} else {
					console.log(error);
					customToast({
						title: 'An error occurred. Please refresh the page to try again.',
						variant: Variants.Danger,
					});
				}
			}).finally(() => setLoading(false));
	};

	const updateAccountData = () => {
		axios
			.patch(route(API_ENDPOINTS.GENERIC.MY_ACCOUNT.EDIT), data)
			.then((res) => {
				setData(res.data.data);
				customToast({
					title: res.data.message,
					variant: Variants.Success,
				});

				getAccountData();
			})
			.catch((error) => {
				if (!!error && !!error.response && !!error.response.data) {
					if (!!error.response.data.message) {
						customToast({
							title: error.response.data.message,
							variant: Variants.Danger,
						});
					}

					if (
						!!error.response.data.data &&
						!!error.response.data.data.errors
					) {
						setErrors(error.response.data.data.errors);
					}
				} else {
					console.log(error);
					customToast({
						title: 'An error occurred. Please refresh the page to try again.',
						variant: Variants.Danger,
					});
				}
			})
			.finally(() => {
				setSubmitting(false);
				setDisabled(false);
			});
	};

	const handleFieldUpdate = (field: string, value: any) => {
		let cloned: any = structuredClone(data);

		cloned[field] = value;

		setData(cloned);
	};


	return (
		<PageLayout
			title={StringHelpers.title(lang?.page?.title || 'My Account')}
			loading={lang === null}
		>
			<WidthConstrainedContainer>
				{loading 
					?  
					<div className="d-flex justify-content-center align-items-center vh-100">
                  		<Spinner />
               		</div> 
					: 
			   <>
				<div className="row m-0 p-0">
					<div className="col-12 col-xxl-6 m-0 p-0 pe-xxl-4">
						<Card>
							<CardBody>
								<SubTitle
									title={StringHelpers.title(
										lang?.page?.sections?.details ||
											'Details'
									)}
									className="mb-3 w-100"
									style={{ color: '#464C5E' }}
								/>
								<div className="row">
									<div className="col-12 col-md-6">
										<FormInput
											type="text"
											label={StringHelpers.title(
												lang?.fields.first_name ||
													'First name'
											)}
											name="first_name"
											value={data?.first_name}
											onChange={(value) =>
												handleFieldUpdate(
													'first_name',
													value
												)
											}
											disabled={disabled || submitting}
											errorMessages={
												!!errors && !!errors.first_name
													? errors.first_name
													: null
											}
										/>
									</div>
									<div className="col-12 col-md-6">
										<FormInput
											type="text"
											label={StringHelpers.title(
												lang?.fields.last_name ||
													'Last name'
											)}
											name="last_name"
											value={data?.last_name}
											onChange={(value) =>
												handleFieldUpdate(
													'last_name',
													value
												)
											}
											disabled={disabled || submitting}
											errorMessages={
												!!errors && !!errors.last_name
													? errors.last_name
													: null
											}
										/>
									</div>
									<div className="col-12 col-md-6 col-lg-12 col-xxl-6">
										<FormInput
											type="text"
											label={StringHelpers.title(
												lang?.fields.email || 'Email'
											)}
											name="email"
											value={data?.email}
											onChange={(value) =>
												handleFieldUpdate(
													'email',
													value
												)
											}
											disabled={disabled || submitting}
											errorMessages={
												!!errors && !!errors.email
													? errors.email
													: null
											}
										/>
									</div>
									<div className="col-12 col-md-6 col-lg-12 col-xxl-6">
										<FormInput
											type="text"
											label={StringHelpers.title(
												lang?.fields.username ||
													'Username'
											)}
											name="username"
											value={data?.username}
											onChange={(value) => {}}
											disabled={true}
										/>
									</div>
									<div className="col-12">
										<FormInput
											type="text"
											label={StringHelpers.title(
												lang?.fields.role || 'Role'
											)}
											name="role"
											value={
												data?.role_name_display ?? ''
											}
											onChange={(value) => {}}
											disabled={true}
										/>
									</div>
									<div className="col-12">
										<PhoneNumberField
											label={StringHelpers.title(
												lang?.fields
													.mobile_phone_number ||
													'Mobile number'
											)}
											name="mobile_phone_number"
											value={data?.mobile_phone_number}
											onChange={(value) =>
												handleFieldUpdate(
													'mobile_phone_number',
													value
												)
											}
											disabled={disabled || submitting}
											errorMessages={
												!!errors?.mobile_phone_number
													? errors.mobile_phone_number
													: !!errors?.mobile_phone_prefix
													? errors.mobile_phone_prefix
													: null
											}
											prefixValue={
												data?.mobile_phone_prefix
											}
											prefixOnChange={(value) =>
												handleFieldUpdate(
													'mobile_phone_prefix',
													value
												)
											}
										/>
									</div>
									<div className="col-12">
										<PhoneNumberField
											label={StringHelpers.title(
												lang?.fields
													.landline_phone_number ||
													'Landline number'
											)}
											name="landline_phone_number"
											value={data?.landline_phone_number}
											onChange={(value) =>
												handleFieldUpdate(
													'landline_phone_number',
													value
												)
											}
											disabled={disabled || submitting}
											errorMessages={
												!!errors?.landline_phone_number
													? errors.landline_phone_number
													: !!errors?.landline_phone_prefix
													? errors.landline_phone_prefix
													: null
											}
											prefixValue={
												data?.landline_phone_prefix
											}
											prefixOnChange={(value) =>
												handleFieldUpdate(
													'landline_phone_prefix',
													value
												)
											}
										/>
									</div>
								</div>
							</CardBody>
						</Card>

						{checkTheme(Themes.Toshiba) &&
							(hasRoleAccess(Roles.COMPANY_MANAGER) ||
								hasRoleAccess(Roles.ENGINEER)) && (
								<div className={'mb-4'}>
									<SubTitle
										title={StringHelpers.title(
											lang?.page?.sections
												?.warranty_distributors ||
												'Warranty Level & Distributors'
										)}
										className="mb-3 w-100"
										style={{ color: '#464C5E' }}
									/>
									<div className="row align-items-center">
										<div className="col-4 col-md-2">
											<img
												src={`https://placehold.co/400x400?text=${data?.toshiba_warranty_level?.name}`}
												className="object-fit-cover"
												style={{
													height: '60px',
													width: '60px',
												}}
											/>
										</div>
										<div className="col-8 col-md-10">
											{data?.distribution_companies?.map(
												(company: any, i: number) => (
													<Card
														key={i}
														noMargin={true}
													>
														<CardBody>
															<div className="fw-bold">
																{company?.name}
															</div>
														</CardBody>
													</Card>
												)
											)}
										</div>
									</div>
								</div>
							)}

						{!checkTheme(Themes.Toshiba) &&
							(hasRoleAccess(Roles.COMPANY_MANAGER) ||
								hasRoleAccess(Roles.ENGINEER)) && (
								<Card>
									<CardBody>
										<SubTitle
											title={
												crud?.sub_titles?.gas_safe ||
												'GasSafe' + ' ' + crud?.sub_titles?.details || 'Details'
											}
											className="mb-3 w-100"
											style={{ color: '#464C5E' }}
										/>
										<GasSafeLookup
											displayCard
											defaultData={{
												engineer:data?.gas_safe_info
											}}
											disabled={disabled || submitting}
											gasSafeNumber={data?.gas_safe_licence_card_number || ''}
											gasSafeConfirmed={data?.gas_safe_confirmed}
											labels={{
												gasSafeNumber:fields?.gas_safe_licence_card_number,
												gasSafeConfirmed:fields?.gas_safe_confirmation_generic,
											}}
											onGasSafeConfirmedChange={(value) => {
												handleFieldUpdate(
													'gas_safe_confirmed',
													value
												)
											}}
											onGasSafeNumberChange={(value) => {
												handleFieldUpdate(
													'gas_safe_licence_card_number',
													value
												)
											}}
											errors={{
												gasSafeConfirmed: errors?.gas_safe_confirmed,
												gasSafeNumber: errors?.gas_safe_licence_card_number
											}}
											type='engineer'

										/>
										
									</CardBody>
								</Card>
							)}

						<Card>
							<CardBody>
								<PostcodeLookup
									data={data}
									setData={setData}
									errors={errors}
								/>
							</CardBody>
						</Card>
					</div>

					<div className="col-12 col-xxl-6 m-0 p-0 ps-xxl-4">
						<Card>
							<CardBody>
								<SubTitle
									title={StringHelpers.title(
										lang?.page?.sections?.optional ||
											'Optional'
									)}
									className="mb-3 w-100"
									style={{ color: '#464C5E' }}
								/>
								<PasswordFieldAndRules
									password={{
										value: data?.password || '',
										label: StringHelpers.title(
											lang?.fields.password || 'Password'
										),
										name: 'password',
										onChange: (value) =>
											handleFieldUpdate(
												'password',
												value
											),
										errorMessages: errors?.password || null,
									}}
									password_confirmation={{
										value:
											data?.password_confirmation || '',
										label: StringHelpers.title(
											lang?.fields
												.password_confirmation ||
												'Confirm Password'
										),
										name: 'password_confirmation',
										onChange: (value) =>
											handleFieldUpdate(
												'password_confirmation',
												value
											),
										errorMessages:
											errors?.password_confirmation ||
											null,
									}}
									lang={lang}
								/>
							</CardBody>
						</Card>
						<AccessLogs lang={lang} />
					</div>
				</div>
				</>

				}
			</WidthConstrainedContainer>
			<BottomAnchoredControls>
				<Button
					label={
						submitting
							? lang?.page.submit_button.submitting ??
							  'Updating...'
							: lang?.page.submit_button.default ?? 'Update'
					}
					className="d-block ms-auto"
					onClick={() => setSubmitting(true)}
					disabled={disabled || submitting}
					variant={Variants.Info}
				/>
			</BottomAnchoredControls>
		</PageLayout>
	);
}

export default MyAccount;

// @ts-ignore
function AccessLogs(props) {
	const {
		lang = {
			page: {
				access_logs: {
					title: 'Access logs',
					ip_address: 'IP Address',
					created_at: 'Date & Time',
				},
			},
		},
	} = props;

	type ExampleListResolverOutput = {
		id: string;
		user_id: string;
		ip_address: string;
		user_agent: string;
		created_at: string;
		updated_at: string;
		permissions: {
			view: boolean;
			update: boolean;
			delete: boolean;
			restore: boolean;
		};
	};

	const colMapping = (data: ExampleListResolverOutput) => [
		{
			id: 'ip_address',
			label: lang?.page?.access_logs?.ip_address || 'IP Address',
			value: data.ip_address,
			sortable: true,
		},
		{
			id: 'created_at',
			label: lang?.page?.access_logs?.created_at ?? 'Date & Time',
			value: format(
				parse(data.created_at, DateFormats.DB, new Date()),
				DateFormats.DATETIME_SHORT
			),
			sortable: true,
		},
	];

	return (
		<DataTable<ExampleListResolverOutput>
			title={StringHelpers.title(
				lang?.page?.access_logs?.title || 'Access logs'
			)}
			routeEndpoint={route(
				API_ENDPOINTS.GENERIC.MY_ACCOUNT.ACCESS_LOG.INDEX
			)}
			colMapping={colMapping}
			incrementPaginationButtons={true}
			variant={Variants.Info}
		/>
	);
}
