function SuccessIcon() {
  return <>
    <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M80 127.64C106.311 127.64 127.64 106.311 127.64 80.0001C127.64 53.6893 106.311 32.3601 80 32.3601C53.6891 32.3601 32.36 53.6893 32.36 80.0001C32.36 106.311 53.6891 127.64 80 127.64Z" fill="#3BC580"/>
    <path d="M72.74 97.3201C72.02 97.3201 71.31 97.0501 70.76 96.5001L56.24 81.9801C55.15 80.8901 55.15 79.1101 56.24 78.0201C57.33 76.9301 59.11 76.9301 60.2 78.0201L72.74 90.5601L99.8 63.5001C100.89 62.4101 102.67 62.4101 103.76 63.5001C104.85 64.5901 104.85 66.3701 103.76 67.4601L74.73 96.4901C74.18 97.0401 73.47 97.3101 72.75 97.3101L72.74 97.3201Z" fill="white"/>
    <path d="M23.81 81.0498C23.23 81.0498 22.76 80.5798 22.76 79.9998C22.76 66.3598 27.59 53.1098 36.37 42.6998C36.74 42.2598 37.41 42.1998 37.85 42.5698C38.29 42.9398 38.35 43.6098 37.98 44.0498C29.52 54.0898 24.87 66.8498 24.87 79.9998C24.87 80.5798 24.4 81.0498 23.82 81.0498H23.81Z" fill="#73DBA0"/>
    <path d="M41.12 121.91C40.86 121.91 40.59 121.81 40.39 121.61C30.87 112.39 24.76 100.1 23.18 86.9898C23.11 86.4098 23.52 85.8898 24.1 85.8198C24.68 85.7498 25.2 86.1598 25.27 86.7398C26.79 99.3598 32.68 111.21 41.86 120.1C42.28 120.5 42.29 121.17 41.88 121.58C41.67 121.79 41.4 121.9 41.13 121.9L41.12 121.91Z" fill="#73DBA0"/>
    <path d="M80.68 137.93C66.91 137.93 53.56 133.01 43.1 124.09C42.66 123.71 42.61 123.05 42.98 122.61C43.36 122.17 44.02 122.12 44.46 122.49C54.54 131.09 67.41 135.83 80.68 135.83C81.26 135.83 81.73 136.3 81.73 136.88C81.73 137.46 81.26 137.93 80.68 137.93Z" fill="#73DBA0"/>
    <path d="M87.3 137.55C86.77 137.55 86.32 137.16 86.26 136.62C86.19 136.04 86.61 135.52 87.18 135.46C100.44 133.92 112.73 127.64 121.81 117.76C122.2 117.33 122.87 117.31 123.29 117.7C123.72 118.09 123.75 118.76 123.35 119.18C113.94 129.43 101.18 135.95 87.42 137.54C87.38 137.54 87.34 137.54 87.3 137.54V137.55Z" fill="#73DBA0"/>
    <path d="M124.96 116.75C124.73 116.75 124.5 116.67 124.3 116.52C123.85 116.16 123.78 115.49 124.14 115.04C132.17 105.1 136.45 93.0697 136.51 80.2697C136.51 79.6897 136.98 79.2297 137.56 79.2297C138.14 79.2297 138.61 79.7097 138.61 80.2897C138.55 93.3897 133.99 106.2 125.78 116.37C125.57 116.63 125.27 116.76 124.96 116.76V116.75Z" fill="#73DBA0"/>
    <path d="M137.16 74.2698C136.64 74.2698 136.18 73.8798 136.12 73.3398C134.59 60.4598 128.54 48.4298 119.08 39.4698C118.66 39.0698 118.64 38.4098 119.04 37.9898C119.44 37.5698 120.11 37.5498 120.52 37.9498C130.33 47.2498 136.61 59.7298 138.2 73.0998C138.27 73.6798 137.86 74.1998 137.28 74.2698C137.24 74.2698 137.2 74.2698 137.15 74.2698H137.16Z" fill="#73DBA0"/>
    <path d="M117.08 37.3498C116.84 37.3498 116.6 37.2698 116.41 37.1098C106.41 28.7698 93.72 24.1798 80.68 24.1798C80.1 24.1798 79.63 23.7098 79.63 23.1298C79.63 22.5498 80.1 22.0798 80.68 22.0798C94.21 22.0798 107.38 26.8398 117.75 35.4998C118.2 35.8698 118.26 36.5298 117.88 36.9798C117.67 37.2298 117.37 37.3598 117.07 37.3598L117.08 37.3498Z" fill="#73DBA0"/>
    <path d="M39.56 41.7597C39.3 41.7597 39.04 41.6597 38.83 41.4697C38.41 41.0697 38.4 40.3997 38.8 39.9897C48.06 30.2997 60.46 24.0797 73.71 22.4897C74.28 22.4197 74.81 22.8297 74.88 23.4097C74.95 23.9897 74.54 24.5097 73.96 24.5797C61.19 26.1097 49.25 32.0997 40.32 41.4397C40.11 41.6597 39.84 41.7597 39.56 41.7597Z" fill="#73DBA0"/>
    <path d="M22.56 47.16C22.38 47.16 22.2 47.11 22.03 47.02C21.53 46.73 21.36 46.08 21.65 45.58C22.63 43.91 23.69 42.25 24.81 40.66C25.14 40.19 25.8 40.07 26.27 40.41C26.74 40.74 26.86 41.4 26.52 41.87C25.43 43.41 24.4 45.02 23.45 46.64C23.26 46.97 22.9 47.16 22.54 47.16H22.56Z" fill="#73DBA0"/>
    <path d="M13.46 83.59C12.9 83.59 12.43 83.14 12.41 82.58C12.38 81.72 12.36 80.86 12.36 80C12.36 69.13 14.84 58.74 19.73 49.11C19.99 48.59 20.63 48.39 21.14 48.65C21.66 48.91 21.86 49.54 21.6 50.06C16.86 59.39 14.46 69.46 14.46 80C14.46 80.84 14.48 81.67 14.51 82.5C14.53 83.08 14.08 83.57 13.5 83.59C13.49 83.59 13.47 83.59 13.46 83.59Z" fill="#73DBA0"/>
    <path d="M47.4 139.52C47.22 139.52 47.04 139.48 46.88 139.38C44.96 138.28 43.07 137.08 41.26 135.8C40.79 135.47 40.67 134.81 41.01 134.34C41.35 133.87 42 133.75 42.47 134.09C44.22 135.33 46.05 136.5 47.92 137.56C48.42 137.85 48.6 138.49 48.31 138.99C48.12 139.33 47.76 139.52 47.4 139.52Z" fill="#73DBA0"/>
    <path d="M80.68 148.32C80.19 148.32 79.7 148.32 79.22 148.3C78.64 148.29 78.18 147.81 78.19 147.23C78.2 146.66 78.67 146.2 79.24 146.2H79.26C79.73 146.2 80.21 146.21 80.68 146.21C90.03 146.21 99.07 144.3 107.54 140.53C108.07 140.29 108.69 140.53 108.93 141.06C109.17 141.59 108.93 142.21 108.4 142.45C99.66 146.34 90.33 148.31 80.68 148.31V148.32Z" fill="#73DBA0"/>
    <path d="M135.77 119.66C135.56 119.66 135.35 119.6 135.17 119.47C134.7 119.14 134.58 118.48 134.91 118.01C136.13 116.27 137.28 114.45 138.33 112.6C138.62 112.1 139.26 111.92 139.76 112.2C140.26 112.49 140.44 113.13 140.16 113.63C139.08 115.54 137.89 117.41 136.63 119.21C136.43 119.5 136.1 119.66 135.77 119.66Z" fill="#73DBA0"/>
    <path d="M147.93 82.71C147.93 82.71 147.91 82.71 147.9 82.71C147.32 82.7 146.86 82.22 146.87 81.64C146.88 81.1 146.89 80.55 146.89 80.01C146.89 70.7 145 61.7 141.26 53.25C141.03 52.72 141.26 52.1 141.8 51.87C142.33 51.64 142.95 51.87 143.18 52.41C147.03 61.12 148.99 70.41 148.99 80.01C148.99 80.57 148.99 81.13 148.97 81.69C148.96 82.26 148.49 82.72 147.92 82.72L147.93 82.71Z" fill="#73DBA0"/>
    <path d="M120.53 26.8499C120.31 26.8499 120.1 26.7799 119.91 26.6499C118.31 25.4699 116.63 24.3499 114.92 23.3099C114.42 23.0099 114.27 22.3599 114.57 21.8699C114.87 21.3699 115.52 21.2199 116.01 21.5199C117.77 22.5899 119.5 23.7399 121.16 24.9599C121.63 25.2999 121.73 25.9599 121.38 26.4299C121.17 26.7099 120.86 26.8599 120.53 26.8599V26.8499Z" fill="#73DBA0"/>
    <path d="M111.37 21.1699C111.21 21.1699 111.04 21.1299 110.89 21.0499C101.49 16.2199 91.33 13.7799 80.69 13.7799C79.85 13.7799 79.01 13.7999 78.18 13.8299C78.17 13.8299 78.15 13.8299 78.14 13.8299C77.58 13.8299 77.11 13.3899 77.09 12.8199C77.07 12.2399 77.52 11.7499 78.1 11.7299C78.95 11.6999 79.82 11.6799 80.69 11.6799C91.67 11.6799 102.15 14.1999 111.85 19.1799C112.37 19.4399 112.57 20.0799 112.3 20.5899C112.11 20.9499 111.75 21.1599 111.37 21.1599V21.1699Z" fill="#73DBA0"/>
    <path d="M29.29 38.0199C29.8699 38.0199 30.34 37.5498 30.34 36.9699C30.34 36.39 29.8699 35.9199 29.29 35.9199C28.7101 35.9199 28.24 36.39 28.24 36.9699C28.24 37.5498 28.7101 38.0199 29.29 38.0199Z" fill="#73DBA0"/>
    <path d="M33.28 34.2199C33.8599 34.2199 34.33 33.7498 34.33 33.1699C34.33 32.59 33.8599 32.1199 33.28 32.1199C32.7001 32.1199 32.23 32.59 32.23 33.1699C32.23 33.7498 32.7001 34.2199 33.28 34.2199Z" fill="#73DBA0"/>
    <path d="M50.59 21.5499C51.1699 21.5499 51.64 21.0798 51.64 20.5C51.64 19.9201 51.1699 19.45 50.59 19.45C50.0101 19.45 49.54 19.9201 49.54 20.5C49.54 21.0798 50.0101 21.5499 50.59 21.5499Z" fill="#73DBA0"/>
    <path d="M124.69 30.63C125.27 30.63 125.74 30.1599 125.74 29.58C125.74 29.0001 125.27 28.53 124.69 28.53C124.11 28.53 123.64 29.0001 123.64 29.58C123.64 30.1599 124.11 30.63 124.69 30.63Z" fill="#73DBA0"/>
    <path d="M128.69 34.5499C129.27 34.5499 129.74 34.0798 129.74 33.5C129.74 32.9201 129.27 32.45 128.69 32.45C128.11 32.45 127.64 32.9201 127.64 33.5C127.64 34.0798 128.11 34.5499 128.69 34.5499Z" fill="#73DBA0"/>
    <path d="M139.9 49.0099C140.48 49.0099 140.95 48.5398 140.95 47.9599C140.95 47.38 140.48 46.9099 139.9 46.9099C139.32 46.9099 138.85 47.38 138.85 47.9599C138.85 48.5398 139.32 49.0099 139.9 49.0099Z" fill="#73DBA0"/>
    <path d="M132.84 124.4C133.42 124.4 133.89 123.93 133.89 123.35C133.89 122.77 133.42 122.3 132.84 122.3C132.26 122.3 131.79 122.77 131.79 123.35C131.79 123.93 132.26 124.4 132.84 124.4Z" fill="#73DBA0"/>
    <path d="M128.97 128.46C129.55 128.46 130.02 127.99 130.02 127.41C130.02 126.83 129.55 126.36 128.97 126.36C128.39 126.36 127.92 126.83 127.92 127.41C127.92 127.99 128.39 128.46 128.97 128.46Z" fill="#73DBA0"/>
    <path d="M113.07 140.29C113.65 140.29 114.12 139.82 114.12 139.24C114.12 138.66 113.65 138.19 113.07 138.19C112.49 138.19 112.02 138.66 112.02 139.24C112.02 139.82 112.49 140.29 113.07 140.29Z" fill="#73DBA0"/>
    <path d="M37.16 133.06C37.7399 133.06 38.21 132.59 38.21 132.01C38.21 131.43 37.7399 130.96 37.16 130.96C36.5801 130.96 36.11 131.43 36.11 132.01C36.11 132.59 36.5801 133.06 37.16 133.06Z" fill="#73DBA0"/>
    <path d="M32.71 129.68C33.2899 129.68 33.76 129.21 33.76 128.63C33.76 128.05 33.2899 127.58 32.71 127.58C32.1301 127.58 31.66 128.05 31.66 128.63C31.66 129.21 32.1301 129.68 32.71 129.68Z" fill="#73DBA0"/>
    <path d="M19.88 111.62C20.4599 111.62 20.93 111.15 20.93 110.57C20.93 109.99 20.4599 109.52 19.88 109.52C19.3001 109.52 18.83 109.99 18.83 110.57C18.83 111.15 19.3001 111.62 19.88 111.62Z" fill="#73DBA0"/>
    </svg>
  </>
}

export default SuccessIcon;
