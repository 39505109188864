import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { API_ENDPOINTS, customToast, Variants, route, AppContainer } from "carrier-fe";
import axios from "axios";

function Register() {
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname !== '/register') return;

    axios.post(
      route(API_ENDPOINTS.REGISTRATION.CREATE)
    )
      .then((res) => {
        navigate('/register/' + res.data.data.id);
      })
      .catch((err) => customToast({
        variant: Variants.Danger,
        title:
          err?.response?.data?.message || 'Something went wrong...',
      }));
  }, []);

  return (
    <AppContainer>
      <Outlet />
    </AppContainer>
  );
};

export default Register;
