import axios from "axios";
import {
  AuthPaneContainer,
  getLocalUrl,
  Title,
  useLangContext,
  useForm,
  route,
  API_ENDPOINTS,
  customToast,
  Variants,
  Button,
  ButtonTypes,
  getThemeFromUrl,
  StringHelpers,
  useDisabledContext,
  LargeThemeLogo,
  SelectableCardInput,
  useNavigationGuard,
  getThemeAuthCover
} from "carrier-fe";
import ViessmannGas from 'carrier-fe/src/images/viessmann_gas.jpg';
import ViessmannHeatpumps from 'carrier-fe/src/images/viessmann_heatpumps.jpg';
import ViessmannOther from 'carrier-fe/src/images/viessmann_other.png';
import ViessmannSolar from 'carrier-fe/src/images/viessmann_solar.jpg';
import { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

const defaultData = {
  company_gas_boiler_systems_enabled: '0',
  company_heat_pump_systems_enabled: '0',
  company_solar_systems_enabled: '0',
  company_other_systems_enabled: '0'
}

type response = {
  data: typeof defaultData;
}

function SystemSelection() {

  useNavigationGuard({ message: 'Are you sure' });

  const { registration, crud } = useLangContext();
  const { registrationId } = useParams();
  const { disabled } = useDisabledContext();
  const navigate = useNavigate();

  useNavigationGuard({ message: 'Are you sure' });

  const skipStepThree = useRef(false);

  const lang = registration?.page?.step_two[getThemeFromUrl()] || null;

  const patch = async (data: typeof defaultData) => {
    const res = await axios.patch(route(API_ENDPOINTS.REGISTRATION.STEP_TWO, { registrationId: registrationId || '' }), data)

    const {
      company_gas_boiler_systems_enabled,
      company_heat_pump_systems_enabled,
      company_solar_systems_enabled,
    } = res.data.data;

    if (
      company_gas_boiler_systems_enabled === false &&
      company_solar_systems_enabled === false &&
      company_heat_pump_systems_enabled === false
    ) {
      await axios.patch(route(API_ENDPOINTS.REGISTRATION.STEP_THREE, { registrationId: registrationId || '' }))
      skipStepThree.current = true;
    }

    return res;
  }

  const { Submit, setValues, errors, store, isSuccess } = useForm(patch, defaultData);
  const hasErrors = Object.keys(errors || {}).length > 0;

  useEffect(() => {
    axios.get<response>(route(API_ENDPOINTS.REGISTRATION.STEP_TWO, { registrationId: registrationId || '' }))
      .then((res) => setValues(res.data.data))
      .catch(err => customToast({
        variant: Variants.Danger,
        title:
          err?.response?.data?.message || 'Something went wrong...',
      }))
  }, [])

  useEffect(() => {
    if (!isSuccess) return;

    if (skipStepThree.current) navigate(
      `/register/${registrationId}/user-details`
    )
    else
      navigate(
        `/register/${registrationId}/system-specific`
      );
  }, [isSuccess])

  return <AuthPaneContainer
    fillerImage={getLocalUrl() + getThemeAuthCover()}
    style={{ "--auth-content-max-width": "90%" } as React.CSSProperties}
    variant="FORM"
  >
    <LargeThemeLogo theme={getThemeFromUrl()} style={{ width: 128 }} />
    <Title
      title={StringHelpers.title(lang?.title || "System Selection")}
    />
    <p>{StringHelpers.sentence(lang?.select_all || '(Please select all that apply)')}</p>
    <div className="d-grid gap-4" style={{ gridTemplateColumns: '1fr 1fr' }}>
      <SelectableCardInput
        className="m-0"
        value={store?.company_gas_boiler_systems_enabled}
        imageSrc={ViessmannGas}
        label={StringHelpers.sentence(lang?.gas_boilers || 'Gas Boilers')}
        isSelected={store.company_gas_boiler_systems_enabled === '1'}
        errorField={hasErrors}
        onChange={(value: any) => {
          setValues({
            company_gas_boiler_systems_enabled: value === '0' ? '1' : '0',
          });
        }}
      />
      <SelectableCardInput
        className="m-0"
        value={store?.company_heat_pump_systems_enabled}
        imageSrc={ViessmannHeatpumps}
        label={StringHelpers.sentence(lang?.heat_pumps || 'Heat Pumps')}
        isSelected={store.company_heat_pump_systems_enabled === '1'}
        errorField={hasErrors}
        onChange={(value: any) => {
          setValues({
            company_heat_pump_systems_enabled: value === '0' ? '1' : '0',
          });
        }}
      />
      <SelectableCardInput
        className="m-0"
        value={store?.company_solar_systems_enabled}
        imageSrc={ViessmannSolar}
        label={StringHelpers.sentence(lang?.solar || 'Solar')}
        isSelected={store.company_solar_systems_enabled === '1'}
        errorField={hasErrors}
        onChange={(value: any) => {
          setValues({
            company_solar_systems_enabled: value === '0' ? '1' : '0',
          });
        }}
      />
      <SelectableCardInput
        className="m-0"
        value={store?.company_other_systems_enabled}
        imageSrc={ViessmannOther}
        label={StringHelpers.sentence(lang?.other || 'Other')}
        isSelected={store.company_other_systems_enabled === '1'}
        errorField={hasErrors}
        onChange={(value: any) => {
          setValues({
            company_other_systems_enabled: value === '0' ? '1' : '0',
          });
        }}
      />
    </div>
    <div className="my-4">
      {Object.values(errors || {}).length > 0 &&
        <i className="text-danger">{Object.values(errors as string)[0]}</i>
      }
    </div>
    <div className="d-flex w-100 justify-content-between">
      <Button
        label={lang?.links?.login || 'Back to login'}
        variant={Variants.Dark}
        type={ButtonTypes.Outline}
        href="/login"
        disabled={disabled}
      />
      <div className="d-flex gap-2">
        <Button
          label={crud?.buttons.previous.default || 'Previous'}
          variant={Variants.Dark}
          type={ButtonTypes.Text}
          onClick={() => navigate(`/register/${registrationId}/overview`)}
          disabled={disabled}
        />
        <Submit
          defaultLabel={crud?.buttons.next.default || "Next"}
          loadingLabel={crud?.buttons.next.submitting || "Saving..."}
          disabled={disabled}
          variant={Variants.Info}
        />
      </div>
    </div>
  </AuthPaneContainer>
}

export default SystemSelection;
