import {
  useForm,
  route,
  API_ENDPOINTS,
  customToast,
  Variants,
  PostcodeLookup,
  AuthPaneContainer,
  getLocalUrl,
  useLangContext,
  getThemeFromUrl,
  Title,
  StringHelpers,
  PasswordFieldAndRules,
  useDisabledContext,
  Button,
  ButtonTypes,
  LargeThemeLogo,
  SubTitle,
  PhoneNumberField,
  useNavigationGuard,
  GasSafeLookup,
  getThemeAuthCover
} from "carrier-fe";
import axios from "axios";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useEffect, useState } from "react"

const defaultData = {
  first_name: '',
  last_name: '',
  email: '',
  username: '',
  landline_phone_prefix: '',
  landline_phone_number: '',
  mobile_phone_prefix: '',
  mobile_phone_number: '',
  password: '',
  password_confirmation: '',
  terms_confirmation: '0',
  address_id: '',
  address_line_1: '',
  address_line_2: '',
  address_line_3: '',
  town_city: '',
  state_county: '',
  postcode_zipcode: '',
  local_authority_code: '',
  sub_country_name: '',
  country_code_iso_3: '',
  gas_safe_licence_card_number: '',
  gas_safe_confirmation: '0'
}

type response = { data: typeof defaultData };

function UserDetails() {

  useNavigationGuard({ message: 'Are you sure' });

  const { registrationId } = useParams();
  const { registration, crud, fields } = useLangContext();
  const { disabled } = useDisabledContext();
  const navigate = useNavigate();
  const [skipStepThree, setSkipStepThree] = useState(false);
  const [hasGasBoiler, setHasGasBoiler] = useState(false);

  useNavigationGuard({ message: 'Are you sure' });

  const lang = registration?.page?.step_four[getThemeFromUrl()] || null;

  const patch = async (data: typeof defaultData) => {
    const res = await axios.patch(route(API_ENDPOINTS.REGISTRATION.STEP_FOUR, { registrationId: registrationId || '' }), data)
    return res;
  }

  const { Submit, Input, setValues, errors, store, isSuccess } = useForm(patch, defaultData);

  useEffect(() => {
    axios.get(route(API_ENDPOINTS.REGISTRATION.STEP_TWO, {registrationId: registrationId || ''}))
      .then((res) => {
        const {
          company_gas_boiler_systems_enabled,
          company_heat_pump_systems_enabled,
          company_solar_systems_enabled,
        } = res.data.data;

        setHasGasBoiler(company_gas_boiler_systems_enabled === '1');

        if (
          company_gas_boiler_systems_enabled === '0' && 
          company_solar_systems_enabled === '0' && 
          company_heat_pump_systems_enabled === '0'
        ) {
          setSkipStepThree(true);
        }
      })
      .catch(err => customToast({
        variant: Variants.Danger,
        title:
          err?.response?.data?.message || 'Something went wrong...',
      }))

    axios.get<response>(route(API_ENDPOINTS.REGISTRATION.STEP_FOUR, { registrationId: registrationId || '' }))
      .then((res) => setValues(
        {
          ...res.data.data,
          landline_phone_prefix: '+44',
          mobile_phone_prefix: '+44',
        }))
      .catch(err => customToast({
        variant: Variants.Danger,
        title:
          err?.response?.data?.message || 'Something went wrong...',
      }))
  }, []);

  useEffect(() => setValues({ username: store.email }), [store.email]);

  useEffect(() => {
    if (!isSuccess) return;

    navigate(
      `/register/${registrationId}/company-details`
    );
  }, [isSuccess])

  return <AuthPaneContainer 
    fillerImage={getLocalUrl() + getThemeAuthCover()} 
    style={{ "--auth-content-max-width": "90%" } as React.CSSProperties}
    variant="FORM" 
  >
      <LargeThemeLogo theme={getThemeFromUrl()} style={{ width: 128 }} />
      <Title
        title={StringHelpers.title(lang?.title || "User Details")}
      />
      <div className="mb-4">
        <div className="row">
          <div className="col-md-6">
            {Input({
              name: 'first_name',
              label: StringHelpers.sentence(fields.first_name || 'First name'),
            })}
          </div>
          <div className="col-md-6">
            {Input({
              name: 'last_name',
              label: StringHelpers.sentence(fields.last_name || 'Last name')
            })}
        </div>
      </div>
        <div className="row">
          <div className="col-md-6">
            {Input({
              name: 'email',
              label: StringHelpers.sentence(fields.email || 'Email')
            })}
          </div>
          <div className="col-md-6">
            {Input({
              name: 'username',
              label: StringHelpers.sentence(fields.username || 'Username'),
              disable: true
            })}
          </div>
        </div>
        <PhoneNumberField 
          name="mobile_phone_number"
          label={StringHelpers.sentence(fields.mobile_phone_number || 'Mobile number')}
          onChange={e => setValues({ mobile_phone_number: e })}
          value={store.mobile_phone_number}
          prefixValue={store.mobile_phone_prefix}
          prefixOnChange={e => setValues({ mobile_phone_prefix: e })}
          disabled={disabled}
        />
        <PhoneNumberField 
          name="landline_phone_number"
          label={StringHelpers.sentence(fields.landline_phone_number || 'Landline number')}
          onChange={e => setValues({ landline_phone_number: e })}
          value={store.landline_phone_number}
          prefixValue={store.landline_phone_prefix}
          prefixOnChange={e => setValues({ landline_phone_prefix: e })}
          disabled={disabled}
        />
        <SubTitle title="Password" />
        <PasswordFieldAndRules
          password={{
            value: store.password || '',
            label: StringHelpers.title(fields?.password || 'Password'),
            name: 'password',
            onChange: (value: string) => setValues({ password: value }),
            errorMessages: !!errors && !!errors.password ? errors.password : null,
          }}
          password_confirmation={{
            value: store.password_confirmation || '',
            label: StringHelpers.title(fields?.password_confirmation || 'Confirm password'),
            name: 'password_confirmation',
            onChange: (value: string) => setValues({ password_confirmation: value }),
            errorMessages: !!errors && !!errors.password_confirmation ? errors.password_confirmation : null,
          }}
          lang={{
            declaration: fields.password.declaration || 'For a strong password please satisfy the below password requirements.',
            rules: {
              min_char: fields.password.min_char || 'Minimum 8 characters',
              lower: fields.password.lower || 'Contains lowercase characters',
              upper: fields.password.upper || 'Contains uppercase characters',
              numeric: fields.password.numeric || 'Contains numeric characters',
              symbols: fields.password.symbols || 'Contains symbols characters',
              confirmed: fields.password.confirmed || 'Passwords must match',
            }
          }}
        />
        {Input({
          type: 'select',
          name: 'terms_confirmation',
          label: StringHelpers.sentence(fields.terms_confirmation || 'I agree to the terms and conditions'),
          options: crud.options.boolean
        })}
        <Link to="">{lang?.terms || '(See terms and conditions)'}</Link>
      </div>
      <PostcodeLookup
        data={store}
        setData={setValues}
        errors={errors}
      />
      { getThemeFromUrl() === "viessmann" && hasGasBoiler && <div className="mt-4">
        <SubTitle title="Gas safe" />
        <p>
        {lang?.gas_safe_desc && 'The details on the Gas Safe licence card that you provide will be checked against the registration details that you provide. If those details don’t match, your registration will need to be verified by a platform admin. Please make sure that the name you provide is identical to the name on your licence card.'}
        </p>
        <GasSafeLookup
           fetchOnLoad
           displayCard
           onGasSafeNumberChange={(v) => {
               setValues({ gas_safe_licence_card_number: v });
           }}
           onGasSafeConfirmedChange={(v) => {
               setValues({ gas_safe_confirmation: v ? '1' : '0' });
           }}
           disabled={disabled}
           gasSafeNumber={store?.gas_safe_licence_card_number}
           gasSafeConfirmed={store?.gas_safe_confirmation === '1'}
           labels={{
               gasSafeNumber:
                 fields?.gas_safe_licence_card_number,
               gasSafeConfirmed: fields?.gas_safe_confirmation_generic,
           }}
           errors={{
               gasSafeNumber: errors?.gas_safe_licence_card_number || '',
               gasSafeConfirmed: errors?.gas_safe_confirmation || '',
           }}
           type={'engineer'}
        />
      </div>}
      <div className="d-flex w-100 justify-content-between mt-4">
        <Button
          label={lang?.links?.login || 'Back to login'}
          variant={Variants.Dark}
          type={ButtonTypes.Outline}
          href="/login"
          disabled={disabled}
        />
        <div className="d-flex gap-2">
          <Button
            label={crud?.buttons.previous.default || 'Previous'}
            variant={Variants.Dark}
            type={ButtonTypes.Text}
            onClick={() => navigate(`/register/${registrationId}/${ skipStepThree ? 'system-selection' :  'system-specific'}`)}
            disabled={disabled}
          />
          <Submit
            defaultLabel={crud?.buttons.next.default || "Next"}
            loadingLabel={crud?.buttons.next.submitting || "Saving..."}
            disabled={disabled}
            variant={Variants.Info}
          />
        </div>
      </div>
  </AuthPaneContainer>
}

export default UserDetails;
